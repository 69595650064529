import React, {useState, useEffect} from 'react'
import styles from "./Map.module.css"

import * as Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';

import mapDataWorld_RU from '../../data/cast.geo.json'
import mapDataWorld_BY from '../../data/cast.geo_by.json'

import {keys} from '../../data/keys';
import proj4 from 'proj4';

import Info from '../Info/Info';
import PopupList from '../PopupList/PopupList';
import Sidebar from '../SideBar/Sidebar';
import Header from '../Header/Header';
import Loader from '../Loader';

if (typeof window !== "undefined") {
    window.proj4 = window.proj4 || proj4;
}

const Map = () => {
    const [heroesList,
        setHeroesList] = useState([]);
    const [position,
        setPosition] = useState({plotX: 0, plotY: 0, list: [], id: null});
    const [isPopup,
        setIsPopup] = useState('');
    const [mapDataСountry,
        setMapDataСountry] = useState('RU');
    const [loading,
        setLoading] = useState(false);
    const [item_,
        setItem] = useState('')

    const mapDataList = {
        'RU': mapDataWorld_RU,
        'BY': mapDataWorld_BY
    }

    const getNormalList = (list) => { //преобразование списка героев
        return list.map(({
            place,
            name,
            id,
            region_id,
            type,
            type_obj
        }) => {
            const [lat,
                lon] = place
                .split(',')
                .map(parseFloat);
            return {
                type_obj,
                z: null,
                lat,
                lon,
                name,
                id,
                region: region_id,
                heroName: type
            };
        });
    };

    useEffect(() => {
        logJSONData('RU')
    }, []); //вызов на получение списка героев при первом рендере

    async function logJSONData(type = 'RU') { //получение списка героев
        setLoading(true);
        try {
            const response = await fetch("https://asmc.yunarmy.ru/iapi/v1.0/get_heroes");
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const jsonData = await response.json();

            const filteredData = type === 'RU'
                ? jsonData
                    .Heroes
                    .filter(el => el.region_id !== 96)
                : jsonData
                    .Heroes
                    .filter(el => el.region_id === 96);

            setHeroesList(getNormalList(filteredData));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    }

    const handleReg = (region, id, plotX, plotY) => { //открытие попапа
        const filteredList = heroesList.filter(el => el.region === region);
        if (filteredList.length > 1) {
            setPosition({id: region, plotX: plotX, plotY: plotY, list: filteredList});
        } else {
            setIsPopup(id);
            setPosition({id: null, plotX: 0, plotY: 0, list: []});
        }
    };

    const handlePopupList = (list) => { //открытие попапа со списком героев
        setIsPopup(list.id);
        setPosition({id: null, plotX: 0, plotY: 0, list: []});
    }
    let tmpArray = [];
    function itemCheck(item) {
        if (tmpArray.indexOf(item.region) == -1 || mapDataСountry == 'BY') {
            tmpArray.push(item.region);
            return true
        } else {
            return false;
        }
    }

    const handleSelect = (val) => { //выбор страны
        const country = val === 'BY'
            ? 'BY'
            : 'RU';
        setMapDataСountry(country);
        logJSONData(country);
    };

    const getColor = (item) => {
        // console.log(item)
    }


    const options = {
        title: {
            text: ""
        },
        mapNavigation: {
            enabled: true
        },
        credits: {
            enabled: false
        },
        chart: {
            animation: false,
            style: {
                boxShadow: '0 34px 48px rgba(255, 255, 255, .5) !important'
            }
        },
        series: [
            {
                name: "Карта",
                mapData: mapDataList[mapDataСountry],
                borderColor: 'rgb(0, 0, 0)',
                showInLegend: false,
                type: 'map',
                data: keys,
                color: '#e7b3b3f0'
            }, 
            {
                marker: {
                    lineWidth: 0,
                    radius: 15,
                    padding: 0
                },
                dataLabels: {
                    verticalAlign: 'top',
                    shadow: false,
                    formatter: function () {
                        this.color = 'red';
                        const val = [...heroesList].filter((item) => item.region == this.point.region);
                        return this.point.region == 96 ? 1 : val.length;
                    },
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        textOutline: 'none',
                        color: '#fff'
                    }
                },
                type: "mappoint",
                name: "Мемориальные объекты",
                showInLegend: false,
                tooltip: {
                    pointFormat: '<b>{point.type}</b><br>',
                    enabled: true,
                    useHTML: true,
                    style: {
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                },
                // Задаем цвет для каждого маркера в зависимости от его id
                data: [...heroesList].filter((item) => itemCheck(item)).map(item => ({
                    ...item,
                    color: item.region === item_.id ? '#091287' : 'brown' // Измените на ваши условия
                })),
                cursor: "pointer",
                point: {
                    events: {
                        click: function () {
                            if (mapDataСountry != 'BY') {
                                handleReg(this.region, this.id, this.plotX, this.plotY);
                            } else {
                                handlePopupList(this);
                            }
                        }
                    }
                }
            }
        ]
    };

    return (
        <div>
            {isPopup
                ? <Info id={isPopup} setIsPopup={setIsPopup}/>
                : <></>}
            {/* //попоп с информацией о герое */}

            {((position.plotX > 0 && position.plotY > 0) && mapDataСountry != 'BY')
                ? <PopupList
                        position={position}
                        setPosition={setPosition}
                        heroesList={heroesList}
                        handlePopupList={handlePopupList}/>

                : <></>}

            <div className={styles.wrapper}>
                <Sidebar/>

                <div className={styles.map}>

                    <div className={styles.header}>
                    <Header mapDataСountry={mapDataСountry} handleSelect={handleSelect} item={item_} setItem={setItem}/> 
                    </div>
                    {loading
                        ? <Loader/>
                        : <HighchartsReact
                            containerProps={{
                            style: {
                                height: `${window.innerWidth > 767
                                    ? "87vh"
                                    : "50vh"}`
                            }
                        }}
                            options={heroesList
                            ? options
                            : ''}
                            highcharts={Highcharts}
                            constructorType={'mapChart'}/>
}
                </div>
            </div>
        </div>
    )
}

export default Map